import {
    AttachMoney as AttachMoneyIcon,
    Ballot as BallotIcon,
    CurrencyExchange as CurrencyExchangeIcon,
    Dashboard as DashboardIcon,
    LibraryBooksTwoTone as BookIcon,
    PieChart as PieChartIcon,
    PeopleOutline as PeopleOutlineIcon,
    Poll as PollIcon,
    MapsHomeWork as MapsHomeWorkIcon,
    LibraryBooks as LibraryBooksIcon,
    EnergySavingsLeafRounded as EnergyIcon,
    DensitySmall as DensitySmallIcon,
    DomainAdd as DomainAddIcon,
    MoneyOff as MoneyOffIcon,
    Link as LinkIcon,
    SwapHoriz as SwapHorizIcon
} from "@mui/icons-material";
import {Box} from "@mui/material";

export const projectsReports: Array<{ id: string, title: string, reportId: string, icon: JSX.Element }> = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        reportId: process.env.REACT_APP_BI_PROJECTS_DASHBOARD || '',
        icon: <DashboardIcon/>,
    },
]

export const portfolioReports: Array<{ id: string, title: string, reportId: string, icon: JSX.Element }> = [
    {
        id: 'ey',
        title: 'EY Reports',
        reportId: process.env.REACT_APP_BI_EY || '',
        icon: <BookIcon/>,
    },
    {
        id: 'investment-dashboard',
        title: 'Investment Dashboard',
        reportId: process.env.REACT_APP_BI_INVESTMENT || '',
        icon: <PieChartIcon/>,
    },
    {
        id: 'cre-dashboard',
        title: 'CRE Dashboard',
        reportId: process.env.REACT_APP_BI_CRE || '',
        icon: <DomainAddIcon/>,
    },
    {
        id: 'covenants',
        title: 'Portfolio Covenants',
        reportId: process.env.REACT_APP_BI_COVENANTS || '',
        icon: <DensitySmallIcon/>,
    },
    {
        id: 'esg',
        title: "ESG - Controversial Rev.",
        reportId: process.env.REACT_APP_BI_ESG || '',
        icon: <EnergyIcon/>,
    },
    {
        id: 'qualtrics',
        title: "Qualtrics Surveys",
        reportId: process.env.REACT_APP_BI_QUALTRICS || '',
        icon: <PollIcon/>,
    },
    {
        id: 'crePortfolio',
        title: "CRE Portfolio Overview",
        reportId: process.env.REACT_APP_BI_CRE_PORTFOLIO || '',
        icon: <MapsHomeWorkIcon/>,
    },
    {
        id: 'investments',
        title: "Investments - Portfolio Overview",
        reportId: process.env.REACT_APP_BI_INVESTMENT_PORTFOLIO || '',
        icon: <LibraryBooksIcon/>,
    },
]

export const financeReports = [
    {
        id: 'cash-arrears',
        title: 'Cash and Arrears',
        reportId: process.env.REACT_APP_BI_CASHARREARS || '',
        icon: <AttachMoneyIcon/>,
    },
    {
        id: 'asset-register',
        title: 'Asset Register',
        reportId: process.env.REACT_APP_BI_ASSETREG || '',
        icon: <BallotIcon/>,
    },
    {
        id: 'xero',
        title: 'XERO All Reports',
        reportId: process.env.REACT_APP_BI_XERO || '',
        // icon: <ClearIcon/>,
        icon: <Box
            component="img"
            sx={{width: 24, height: 24}}
            src={`/static/xero24.png`} alt='xero'
        />
    },
]

export const investorReports = [
    {
        id: 'solvexia',
        title: 'Solvexia Duplication',
        reportId: process.env.REACT_APP_BI_SOLVEXIA || '',
        icon: <Box
            component="img"
            sx={{width: 24, height: 24}}
            src={`/static/Solvexia24.png`} alt='Solvexia'
        />
    },
    {
        id: 'aum',
        title: 'AUM Numbers',
        reportId: process.env.REACT_APP_BI_AUM || '',
        icon: <PieChartIcon/>,
    },
    {
        id: 'pinnacle',
        title: 'Pinnacle',
        reportId: process.env.REACT_APP_BI_PINNACLE || '',
        // icon: <AttachMoneyIcon/>,
        icon: <Box
            component="img"
            sx={{width: 24, height: 24}}
            src={`/static/pinnacle24.png`} alt='Pinnacle'
        />
    },
]

export const loanAdminReports = [
    {
        id: 'loanAdmin',
        title: 'Loan Admin',
        reportId: process.env.REACT_APP_BI_LOAN || '',
        icon: <MoneyOffIcon/>
    },
    {
        id: 'axcessSalesforce',
        title: 'Axcess and Salesforce Accuracy',
        reportId: process.env.REACT_APP_BI_AXCESS_SALESFORCE || '',
        icon: <LinkIcon/>
    },
]

export const itReports = [
    {
        id: 'adPerm',
        title: 'AD Permissions',
        reportId: process.env.REACT_APP_BI_AD_PERM || '',
        icon: <PeopleOutlineIcon/>
    },
]

export const auxReports = [
    {
        id: 'bbswRates',
        title: 'BBSW Rates',
        reportId: process.env.REACT_APP_BI_BBSW || '',
        icon: <SwapHorizIcon/>
    },
    {
        id: 'fxRates',
        title: 'FX Rates',
        reportId: process.env.REACT_APP_BI_FX || '',
        icon: <CurrencyExchangeIcon/>
    },
]