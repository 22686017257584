// @mui
import {Container, Stack, Typography} from '@mui/material';
import {
    Calculate as CalculateIcon,
    Construction as ConstructionIcon,
    LibraryBooksTwoTone as BookIcon,
    List as ListIcon,
    MoneyOff as MoneyOffIcon,
    People as PeopleIcon,
} from "@mui/icons-material";
// Components
import {Page} from '../../layouts/components';
import {ReportSelection} from "./BIComponents";

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

// Landing Page
export default function Reports() {

    return (
        <Page title="Power BI Reports">
            <Container sx={{m: 2}} maxWidth={false}>
                <Typography variant="h3" paragraph align="left">
                    Reports
                </Typography>
                <Stack direction="column" spacing={2}>
                    <ReportSelection
                        title="Projects Dashboard"
                        icon={<ConstructionIcon/>}
                        routeTo="/reports/projects"
                    />
                    <ReportSelection
                        title="Portfolio"
                        icon={<BookIcon/>}
                        routeTo="/reports/portfolio"
                    />
                    <ReportSelection
                        title="Finance"
                        icon={<CalculateIcon/>}
                        routeTo="/reports/financial"
                    />
                    <ReportSelection
                        title="Investor"
                        icon={<PeopleIcon/>}
                        routeTo="/reports/investor"
                    />
                    <ReportSelection
                        title="Loan Admin"
                        icon={<MoneyOffIcon/>}
                        routeTo="/reports/loan-admin"
                    />
                    <ReportSelection
                        title="IT"
                        icon={<ListIcon />}
                        routeTo="/reports/it"
                    />
                    <ReportSelection
                        title="Auxiliary"
                        icon={<ListIcon />}
                        routeTo="/reports/auxiliary"
                    />
                </Stack>
            </Container>
        </Page>
    );
}
