import {createAsyncThunk} from "@reduxjs/toolkit";
import {apiAuthGetRequests, apiAuthPostRequests, apiGetRequests} from "../apiUtils";
import {addNotification} from "../notifications/notificationSlice";
import {RootState} from "../store";

// QUERY API IF VALID COOKIE AND USER
export const checkUserAuth = createAsyncThunk('user/checkUserAuth', async (_, thunkAPI) => {
    try {
        // CHECKED IF VALID TOKENS IN COOKIE
        await apiAuthGetRequests('/');
        return thunkAPI.dispatch(getUser());
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

// RETRIEVE USER
export const getUser = createAsyncThunk('user/getUser', async (_, thunkAPI) => {
    try {
        const mipadUser = await apiGetRequests('/user');
        const azureUser = await apiAuthGetRequests('/user');

        return {
            ...azureUser,
            mipadId: mipadUser.mipadUserId,
            permissions: mipadUser.permissions
        };
    } catch (error) {
        let message;
        if (error instanceof Error) {
            message = `Error: ${error.message}`;
        } else {
            message = 'Problem occurred retrieving User';
        }
        thunkAPI.dispatch(addNotification(message, 'error'))
        return thunkAPI.rejectWithValue(message);
    }
})

// SignOUt User
export const signOut = createAsyncThunk('user/signOut', async (_, thunkAPI) => {
    try {
        // Clear Cookies
        await apiAuthPostRequests('/user/signOut');

        // UI Notification
        const state = thunkAPI.getState() as RootState;

        let confirm = 'Successfully Logged Out!';
        if (state.user?.user?.givenName) {
            confirm = `Goodbye ${state.user.user.givenName}!`
        }

        thunkAPI.dispatch(addNotification(confirm, 'success'));

        return;
    } catch (error) {
        thunkAPI.dispatch(addNotification('Error Signing out.', 'error'));
        console.log('Error signing out: ', error);
    }
})