import './App.css';
import {SnackbarProvider} from 'notistack';
import ThemeProvider from './theme';
import Router from './Router/index'
// MUI
import {Clear as ClearIcon} from "@mui/icons-material";
import {Box, IconButton} from '@mui/material';
// Store
import {useAppDispatch} from "./store/store";
import {closeNotification} from "./store/notifications/notificationSlice";

// Root of application
function App() {
    const dispatch = useAppDispatch();

    return (
        <ThemeProvider>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                action={(key) => (
                    // <IconButton sx={{color: 'common.white'}} onClick={() => dispatch(closeNotification(key as string))}>
                    <IconButton sx={{color: 'common.white'}} onClick={() =>  dispatch(closeNotification(key as string))}>
                        <ClearIcon/>
                    </IconButton>
                )}
            >
                <Box sx={{minWidth: 800}}>
                    <Router/>
                </Box>
            </SnackbarProvider>
        </ThemeProvider>
    )
}

export default App;
