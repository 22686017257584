import {Navigate, Route, Routes} from "react-router-dom";
// Pages
import {Reports} from "./index";
import Page404 from "../Page404";
import PortfolioReports from "./PortfolioReports";
import FinancialReports from "./FinancialReports";
import InvestorReports from "./InvestorReports";
import LoanAdminReports from "./LoanAdminReports";
import ITReports from "./ITReports";
import AuxiliaryReports from "./AuxiliaryReports";
import ProjectsReports from "./ProjectsReports";

// Landing Page
export default function Main() {

    return (
        <Routes>
            <Route path='/' element={<Navigate to='home'/>}/>
            <Route path='/home' element={<Reports/>}/>
            <Route path='/projects/*' element={<ProjectsReports/>}/>
            <Route path='/portfolio/*' element={<PortfolioReports/>}/>
            <Route path='/financial/*' element={<FinancialReports/>}/>
            <Route path='/investor/*' element={<InvestorReports/>}/>
            <Route path='/loan-admin/*' element={<LoanAdminReports/>}/>
            <Route path='/it/*' element={<ITReports/>}/>
            <Route path='/auxiliary/*' element={<AuxiliaryReports/>}/>

            <Route path='/*' element={<Page404/>}/>
        </Routes>
    );
}
