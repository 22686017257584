import {Route, Routes} from "react-router-dom";
// @mui
import {Container, Stack, Typography} from "@mui/material";
// Compoonents
import Page from "../../layouts/components/Page";
import Page404 from "../Page404";
import {Report, ReportSelection} from "./BIComponents";
import {loanAdminReports} from "../../config/BIReportsConfig";

export default function LoanAdminReports() {

    return (
        <>
            <Routes>
                <Route
                    path='/'
                    element={
                        <Page title="Power BI - Loan Admin">
                            <Container maxWidth={false} sx={{maxWidth: '100%'}}>
                                <Typography variant="h3" paragraph align="left">
                                    Loan Admin Reports
                                </Typography>
                                <Stack direction="column" spacing={2}>
                                    {loanAdminReports.map((report) =>
                                        <ReportSelection
                                            title={report.title}
                                            icon={report.icon}
                                            routeTo={`/reports/loan-admin/${report.id}`}
                                            key={report.id}
                                        />
                                    )}
                                </Stack>
                            </Container>
                        </Page>
                    }
                />
                {loanAdminReports.map((report) =>
                    <Route key={report.id} path={`/${report.id}`} element={
                        <Report
                            category={'Portfolio'}
                            title={report.title}
                            reportId={report.reportId}
                        />
                    }/>
                )}
                <Route path='/*' element={<Page404/>}/>
            </Routes>

        </>
    )
}